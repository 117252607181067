<template>
  <div class="advantage show-mokuai d_jump wow bounceInLeft" data-wow-duration ="1.5s" data-wow-delay ="0.2s" index="03" id="Advantage">
    <div class="title">我们的优势</div>
    <div class="content">
      <img class="show-img clear" :src="cdnUrl + '/platform/static/youshi01.png'"/>
      <div class="info">
        <img  :src="cdnUrl + '/platform/static/advantage_di.png'" class="shuiyin">
        <div class="info-title">赋能自然课堂</div>
        <div class="info-tips">
          海量物种资源 X 24小时动物直播 X 倾听大自然<br/> X 云守护中国旗舰动物
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Advantage',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
