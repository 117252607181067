<template>
  <!--权威机构-->
  <div class="authority show-mokuai wow bounceInRight" data-wow-duration ="1.5s" data-wow-delay ="0.2s" id="Authority">
    <div class="content">
      <div class="info">
        <img class="shuiyin" :src="cdnUrl + '/platform/static/Facilitate _teaching _innovation.png'">
        <div class="info-title">助力教学创新</div>
        <div class="info-tips">
          情景化直播教学 X PBL教学分层解决方案<br/> X DIY教学课件模板 X 教辅教具
        </div>
      </div>
      <img class="show-img clear" :src="cdnUrl + '/platform/static/chuangxin01.png'"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Authority',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
