<template>
  <div class="school-try" style="z-index: 1000">
    <div class="title">欢迎入驻!</div>
    <div class="try-form-box">
      <i class="el-icon-close close-position" @click="$emit('closetry')"></i>
      <div class="try-left">
        <div class="steps" :class="{'active': tryStep === 'step1'}"><div class="num">❶</div>填写学校信息</div>
        <div class="steps"  @click="checkStype2" :class="{'active': tryStep === 'step2'}"><div class="num">❷</div>注册管理员账号</div>
      </div>
      <div class="try-right" >
        <el-form v-show="tryStep === 'step1'" class="try-form" :model="tryStep1" :rules="rules" ref="tryStep1" label-width="120px">
          <el-form-item label="学校名称" prop="name" class="form-input-item">
            <el-input v-model.trim="tryStep1.name" placeholder="请输入学校名称"></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="areaCodes" class="form-input-item" >
            <el-cascader
              ref="cascader"
              style="width: 100%"
              v-model="tryStep1.areaCodes"
              :options="areas"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address" class="form-input-item">
            <el-input v-model.trim="tryStep1.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="办学性质" prop="orgType" class="form-input-item">
            <el-select v-model="tryStep1.orgType" placeholder="请选择" style="width: 100%">
              <el-option
                v-for="item in schoolNature"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="营业执照编号" prop="orgCode" class="form-input-item">
            <el-input v-model.trim="tryStep1.orgCode" placeholder="请输入学校营业执照编号"></el-input>
          </el-form-item>
          <el-form-item label="营业执照图片" prop="orgLicense" class="form-input-item">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :http-request="uploadCos"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :before-upload="beforeAvatarUpload">
              <img v-if="tryStep1.orgLicense" :src="tryStep1.orgLicense" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <div>
            <el-checkbox v-model="checked"><span style="color: #333333;font-size: 1rem;" >我已阅读并同意<span style="color: #68CE1C;font-weight: bold;font-size: 1rem;" @click.stop.prevent="showAgreement()">《用户协议》</span></span></el-checkbox>
            <div class="register-btn" :style="{'background':canNext?'':'#999999','cursor': canNext?'':'not-allowed'}" @click=" canNext?goToTry():''">下一步<i class="el-icon-loading" v-if="loading"></i></div>
          </div>
        </el-form>
        <el-form v-show="tryStep === 'step2'" class="try-form" :model="tryStep2" :rules="rules2" ref="tryStep2" label-width="100px">
          <el-form-item label="手机号码" prop="phone" >
            <el-input v-model="tryStep2.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码"  prop="verifycode" >
            <div style="display: flex;">
              <el-input v-model.trim="tryStep2.verifycode" placeholder="请输入验证码"   style="flex: 1"></el-input>
              <div class="yanzhengma-btn" v-show="count <= 0" @click="sendYanZhengMa()">发送验证码</div>
              <div class="yanzhengma-btn-load" v-show="count > 0" >{{count}}s</div>
            </div>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="realName" class="form-input-item">
            <el-input v-model="tryStep2.realName" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="登录用户名" class="form-input-item" prop="userName">
            <el-input v-model="tryStep2.userName" placeholder="请输入用户名(不填默认为手机号)"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email" class="form-input-item">
            <el-input v-model="tryStep2.email" placeholder="请输入联系人邮箱"></el-input>
            <span style="font-size: 1rem;color:#666666">邮箱用于接收重要通知</span>
          </el-form-item>
          <el-form-item label="设置密码" prop="password" >
            <el-input v-model="tryStep2.password" type="password" placeholder="请设置6~20位密码"></el-input>
          </el-form-item>
          <div>
            <el-checkbox v-model="checked"><span style="color: #333333;font-size: 1rem;" >我已阅读并同意<span style="color: #68CE1C;font-weight: bold;font-size: 1rem;" @click.stop.prevent="showAgreement()">《用户协议》</span></span></el-checkbox>
            <div class="register-btn" :style="{'background':canReg?'':'#999999','cursor': canReg?'':'not-allowed'}" @click="canReg?registerNow():''">立即注册<i class="el-icon-loading" v-if="loading"></i></div>
          </div>
        </el-form>
      </div>
    </div>
    <!--显示用户协议的弹出层-->
    <el-dialog title="行知自然教育平台用户协议" :visible.sync="dialogAgreementVisible" append-to-body :modal="false">
      <div style="height: 50vh;overflow-y: scroll">
        <Agreement></Agreement>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="pub-btn" @click="agreeAndContinue()">同意并继续</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cityData } from '@/utils/area'
import { isvalidPhone } from '@/utils/valid'
import Agreement from '@/components/Agreement'
import COS from 'cos-js-sdk-v5'
import { v4 as uuidv4 } from 'uuid'
import { uploadKey, createSchool, createUser, sendVCode } from '@/api/api'
// 定义一个全局的变量，谁用谁知道
var validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入电话号码'))
  } else if (!isvalidPhone(value)) {
    callback(new Error('请输入正确的11位手机号码'))
  } else {
    callback()
  }
}
var checkEmail = (rule, value, callback) => {
  const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  if (!value) {
    return callback(new Error('邮箱不能为空'))
  }
  setTimeout(() => {
    if (mailReg.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的邮箱格式'))
    }
  }, 100)
}
export default {
  name: 'SettleIn',
  components: {
    Agreement
  },
  data () {
    return {
      loading: false,
      dirType: 'PlatformUsr', // 跟图片上传地址有关
      checked: false,
      count: 0,
      imageUrl: '',
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      schoolNature: [
        { value: '公办学校', label: '公办学校' },
        { value: '民办学校', label: '民办学校' },
        { value: '国际学校', label: '国际学校' }
      ], // 学校性质
      areas: cityData,
      tryStep: 'step1', // 当前试用进行的步骤
      dialogAgreementVisible: false, // 是否显示协议的展示窗口
      tryStep1: {
        areaCodes: [], // 所在地区code
        areaNames: [], // 所在地区名字
        name: '', // 学校名称
        address: '', // 学校详细地址
        orgCode: '', // 营业执照编号
        orgLicense: '', // 营业执照图片
        orgType: '' // 学校类型
      },
      tryStep2: {
        realName: '', // 学校管理员姓名
        email: '', // 电子邮箱
        phone: '', // 手机号
        userName: '', // 用户名
        password: '', // 登录密码
        verifycode: '', // 短信验证码
        school: null // 关联学校ID
      },
      rules2: {
        realName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'change'
          }
        ],
        userName: [
          { trigger: 'change', pattern: /^[0-9a-zA-Z_\-.]{3,20}$/, message: '请输入3~20位用户名' }
        ],
        email: [
          {
            required: true,
            message: '请输入电子邮箱',
            trigger: 'change'
          },
          { required: true, trigger: 'change', validator: checkEmail, message: '请输入正确的邮箱' }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'change'
          },
          { required: true, trigger: 'change', validator: validPhone, message: '请输入正确的手机号' } // 这里需要用到全局变量
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change'
          },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'change' }
        ],
        verifycode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'change'
          },
          { min: 4, max: 4, message: '验证码长度为4位', trigger: 'change' }
        ]
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入学校名称',
            trigger: 'change'
          }
        ],
        areaCodes: [
          {
            required: true,
            message: '请选择所在地区',
            trigger: 'change'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入详细地址',
            trigger: 'change'
          }
        ],
        orgCode: [
          {
            required: true,
            message: '请输入营业执照编号',
            trigger: 'change'
          }
        ],
        orgLicense: [
          {
            required: true,
            message: '请上传营业执照',
            trigger: 'change'
          }
        ],
        orgType: [
          {
            required: true,
            message: '请选择办学性质',
            trigger: 'change'
          }
        ]
      },
      errMsg: {
        '-1': '系统出现未知异常！',
        99996: '请求来源网站无效！',
        99997: '验证码错误，请重新输入！',
        99998: '无效验证码，请重新发送验证码！',
        99999: '手机号无效，请填写正确的手机号！',
        100002: '该学校信息已注册，请核实！',
        100000: '验证码发送失败，请重新尝试！',
        130001: '真实姓名不能空，请填写！',
        130004: '手机号不能空，请填写！',
        130005: '用户名不能空，请填写！',
        130006: '手机号被占用，请换一个手机号！',
        130007: '用户名被占用，请换一个用户名！',
        130008: '登录密码不能为空，请输入登录密码！',
        130009: '电子邮箱格式错误，请输入正确的邮箱！',
        130010: '关联学校ID格式错误，请重试或联系管理员！',
        160001: '请指定上传目录类型',
        160002: '指定上传目录类型无效'
      }
    }
  },
  computed: {
    canNext: function () {
      if (!this.checked) {
        return false
      }
      if (!this.tryStep1.name) {
        return false
      }
      if (!this.tryStep1.areaCodes) {
        return false
      }
      if (!this.tryStep1.areaNames) {
        return false
      }
      if (!this.tryStep1.address) {
        return false
      }
      if (!this.tryStep1.orgCode) {
        return false
      }
      if (!this.tryStep1.orgLicense) {
        return false
      }
      if (!this.tryStep1.orgType) {
        return false
      }
      return true
    },
    canReg: function () {
      if (!this.checked) {
        return false
      }
      if (!this.tryStep2.realName) {
        return false
      }
      if (!this.tryStep2.email) {
        return false
      }
      if (!this.tryStep2.phone) {
        return false
      }
      if (!this.tryStep2.password) {
        return false
      }
      if (!this.tryStep2.verifycode) {
        return false
      }
      return true
    }
  },
  methods: {
    // 检查点击第二步
    checkStype2 () {
      if (this.tryStep1._id) {
        // 用户已经注册了第一步
      } else {
        this.$message.error('请先填写学校信息后点击下一步')
      }
    },
    async uploadCos (fileObj) {
      console.log(fileObj)
      const vm = this
      const file = fileObj.file
      try {
        // 请求服务器接口获取上传参数
        const ret = await uploadKey({ dirType: vm.dirType })
        const uploadParams = ret.data
        var cos = new COS({
          // 必选参数
          getAuthorization: async (options, callback1) => {
            const ret = await uploadKey({ dirType: vm.dirType })
            const keys = ret.data
            callback1({
              TmpSecretId: keys.credentials.tmpSecretId,
              TmpSecretKey: keys.credentials.tmpSecretKey,
              XCosSecurityToken: keys.credentials.sessionToken,
              StartTime: keys.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: keys.expiredTime, // 时间戳，单位秒，如：1580000900
              ScopeLimit: true
            })
          }
        })
        // 上传
        cos.putObject({
          Bucket: uploadParams.bucket, // 存储桶
          Region: uploadParams.region, // 存储桶所在地域
          Key: uploadParams.allowPrefix + uuidv4() + (file.name.lastIndexOf('.') !== -1 ? file.name.substr(file.name.lastIndexOf('.')) : ''), //  文件名 必须
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          onProgress: function (progressData) {
            // 上传进度数据
            console.log(JSON.stringify(progressData))
          }
        }, (err, data) => {
          console.info(err)
          console.info(data)
          if (err) {
          } else {
            if (data.statusCode === 200) {
              // 获得上传的url
              const url = data.Location.substr(data.Location.indexOf('/'))
              console.info(url)
              vm.tryStep1.orgLicense = this.$store.state.cdnUrl + url
              // todo
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      return true
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    // 点击同意并继续
    agreeAndContinue () {
      this.dialogAgreementVisible = false
      this.checked = true
    },
    // 查看协议
    showAgreement () {
      this.dialogAgreementVisible = true
    },
    // 发送验证码
    async sendYanZhengMa () {
      const res = await sendVCode({
        phone: this.tryStep2.phone
      })
      if (res.code === 0) {
        this.$message({
          message: '验证码发送成功！',
          type: 'success'
        })
        this.count = 60
        this.timer = setInterval(() => {
          this.count = this.count - 1
          if (this.count === 0) {
            clearInterval(this.timer)
          }
        }, 1000)
      } else {
        if (this.errMsg[res.code]) {
          this.$message.error(this.errMsg[res.code])
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 注册完毕前往试用
    goToTry () {
      if (this.loading) {
        return
      } else {
        this.loading = true
      }
      if (!this.checked) {
        this.$message.error('请先勾选用户协议！')
        this.loading = false
        return
      }
      // console.info(this.$refs.cascader.getCheckedNodes())
      const formName = 'tryStep1'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('请确保学校信息填写正确，下一步后不能返回修改！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const temp = this.$refs.cascader.getCheckedNodes()
            this.tryStep1.areaNames = temp[0].pathLabels
            this.loading = false
            this.saveSchool(this.tryStep1)
          }).catch(() => {
            this.loading = false
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        } else {
          // this.$message.error('失败！')
          this.$message({
            message: '您填写的信息有误，请检查！',
            type: 'warning'
          })
          this.loading = false
          return false
        }
      })
    },
    // 保存学校信息
    async saveSchool (params) {
      const res = await createSchool(params)
      if (res.code === 0) {
        this.$message({
          message: '学校信息填写成功！',
          type: 'success'
        })
        // 6078f7f89c6e5d33cd6e76c5
        this.tryStep1._id = res.data._id
        this.tryStep = 'step2'
      } else {
        if (this.errMsg[res.code]) {
          this.$message.error(this.errMsg[res.code])
        } else {
          this.$message.error(res.msg)
        }
      }
      this.loading = false
    },
    // 保存管理员信息
    async saveUser (params) {
      const res = await createUser(params)
      if (res.code === 0) {
        this.$message({
          message: '注册成功，请等待客服人员审核并与您联系！',
          type: 'success'
        })
        this.$emit('closetry')
      } else {
        if (this.errMsg[res.code]) {
          this.$message.error(this.errMsg[res.code])
        } else {
          this.$message.error(res.msg)
        }
      }
      this.loading = false
    },
    // 点击立即注册
    registerNow () {
      if (this.loading) {
        return
      } else {
        this.loading = true
      }
      if (!this.checked) {
        this.$message({
          message: '请先勾选用户协议！',
          type: 'warning'
        })
        this.loading = false
        return
      }
      const formName = 'tryStep2'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.tryStep2.userName) {
            this.tryStep2.userName = this.tryStep2.phone
          }
          this.tryStep2.school = this.tryStep1._id
          this.saveUser(this.tryStep2)
        } else {
          this.$message({
            message: '您填写的信息有误，请检查！',
            type: 'warning'
          })
          this.loading = false
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
<style>
.el-button--primary{
  background-color: #67CE1C;
  border-color: #67CE1C;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
