<template>
  <div  class="invitation d_jump "  index="04" id="Invitation">
    <div class="title">
      特邀权威专家
    </div>
    <div class="export-list">
      <div class="export-info wow flipInY" data-wow-duration ="2.4s" data-wow-delay ="0.2s">
        <img :src="cdnUrl + '/platform/static/expert01.png'" style="height: 220px;width: 267px;object-fit: cover;" />
        <div class="info-box">
          <div class="name">张和民</div>
          <div class="tips">大熊猫之父</div>
          <div class="jianjie">中国大熊猫保护研究中心党委副书记、常务副主任，教授级高工。他组织科研攻关，成功地攻克了大熊猫发情难、配种难、成活难三大繁育难题，在大熊猫人工繁育的研究中取得了突出成就，被誉为“大熊猫之父”。</div>
        </div>
      </div>
      <div class="export-info wow flipInY" data-wow-duration ="1.5s" data-wow-delay ="0.2s">
        <img :src="cdnUrl + '/platform/static/zj_zhangzhixiang.png'" style="height: 220px;width: 267px;object-fit: cover;" />
        <div class="info-box">
          <div class="name">张志翔</div>
          <div class="tips">植物学专家</div>
          <div class="jianjie">北京林业大学教授，博士生导师，北京林业大学标本馆馆长兼理科基地主任，国家林业局自然保护区研究中心野生植物研究室主任。主要研究领域为植物分类与系统学、植物生殖生态学、野生植物保护生物学。</div>
        </div>
      </div>
      <div class="export-info wow flipInY" data-wow-duration ="2.1s" data-wow-delay ="0.2s">
        <img :src="cdnUrl + '/platform/static/shikun.png'" style="height: 220px;width: 267px;object-fit: cover;" />
        <div class="info-box">
          <div class="name">时坤</div>
          <div class="tips">野生动物专家</div>
          <div class="jianjie">现任北京林业大学野生动物研究所所长，研究领域主要为保护生物学、动物生态学、生态系统管理；国际自然保护联盟（IUCN）猫科动物专家组专家和IUCN世界保护地委员会委员；全球雪豹保护网络指导委员会委员。</div>
        </div>
      </div>
      <div class="export-info wow flipInY" data-wow-duration ="1.8s" data-wow-delay ="0.2s">
        <img :src="cdnUrl + '/platform/static/zhangjinshuo.png'" style="height: 220px;width: 267px;object-fit: cover;" />
        <div class="info-box">
          <div class="name">张劲硕</div>
          <div class="tips">国家动物博物馆副馆长</div>
          <div class="jianjie">中国科学院动物研究所博士、高级工程师，国家动物博物馆科普策划人；主要研究哺乳动物分类学、保护生物学，曾在英国自然历史博物馆 、布里斯托尔大学做访问学者 ；现主要从事科普教育和科学传播工作。</div>
        </div>
      </div>
<!--      <div class="export-info wow flipInY" data-wow-duration ="1.5s" data-wow-delay ="0.2s">
        <img :src="cdnUrl + '/platform/static/expert05.png'" style="height: 220px;width: 267px;object-fit: cover;" />
        <div class="info-box">
          <div class="name">侯元生</div>
          <div class="tips">生态保护卫士</div>
          <div class="jianjie">青海湖国家级自然保护区管理局工作者，在青海湖生态保护一线从事生态保护和生态科普工作27年，先后荣获全国保护野生动植物和资源环境先进个人，生态保护卫士，全省十佳科普之星等荣誉。</div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Invitation',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
