<template>
  <div class="expert show-mokuai wow bounceInRight" data-wow-duration ="1.5s" data-wow-delay ="0.2s" id="Expert">
    <div class="content ">
      <div class="info">
        <img :src="cdnUrl + '/platform/static/Cultivate_environmental _awareness.png'" class="shuiyin">
        <div class="info-title">培养环保意识</div>
        <div class="info-tips">
          公益自然课堂 X 志愿者活动 X 社会实践活动<br/> X 中外校际交流
        </div>
      </div>
      <img class="show-img clear" :src="cdnUrl + '/platform/static/zhuanjia.png'" style="border-radius: 6px"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expert',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
