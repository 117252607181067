<template>
  <div class="Boutique show-mokuai wow bounceInLeft" data-wow-duration ="1.5s" data-wow-delay ="0.2s" id="Boutique">
    <div class="content">
      <img class="show-img clear" :src="cdnUrl + '/platform/static/tansuo01.png'" />
      <div class="info">
        <img :src="cdnUrl + '/platform/static/Guide _autonomous _exploration.png'" class="shuiyin">
        <div class="info-title">引导自主探索</div>
        <div class="info-tips">
          精品线上课程 X 独家线下拓展 X 权威专家面对面<br/> X 主流媒体择优展示
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Boutique',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
