<template>
  <div class="Agreement">
    <p>
      汉雅星空文化科技有限公司在此特别提醒您（用户）在注册成为用户、使用行知自然教育平台的服务<span class="bold">（简称本服务）</span>之前，请认真阅读本《用户协议》（以下简称“本协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议，包括但不限于免除或者限制行知自然教育平台责任的条款，特别是<span class="bold">以加粗的方式注明的</span>免除或限制责任的条款、争议解决和法律适用条款。本协议适用范围具体指：行知自然网站（https://www.gonature.cn）
    </p>
    <p>
      当您按照注册页面提示填写信息、阅读并同意本协议由且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他行知自然教育平台允许的方式实际使用本服务时，或以其他任何明示或默示方式表示接受本协议的，即表示您已充分阅读、理解并接受本协议的全部内容，您已同意签署本协议，本协议即产生法律约束力，届时您不应以未阅读本协议的内容或者未获得行知自然教育平台对您问询的解答等理由，主张本协议无效，或要求撤销本协议。    </p>
    <p>
      如您是未满18周岁的未成年人，或虽满18周岁但属于不能完全辨认自己行为的限制民事行为能力人，您应请您的监护人代您注册行知自然教育平台用户账号或接受本协议以及使用本平台的服务，或者经您的监护人同意注册行知自然教育平台用户账号、接受本协议以及使用本平台的服务，并特别注意未成年人和无行为能力人使用条款。如您是未满8周岁的未成年人或属于不能辨认自己行为的成年人，则应当由您的监护人代您注册行知自然教育平台用户账号或接受本协议以及使用本平台的服务，并特别注意未成年人和无行为能力人使用条款，您和您的监护人均受本协议条款的约束，并对行知自然教育平台账号和服务的使用负责。    </p>
    <div class="text-box">
      <div class="title">一、协议的范围</div>
      <div>
        <div>
          1. 本协议约定汉雅星空科技有限公司与用户之间关于使用行知自然教育平台服务的权利义务。“用户”是指注册、登录、使用本服务的法人、非法人组织或自然人。
        </div>
        <div>
          2. 行知自然教育平台服务是指汉雅星空科技有限公司向用户提供的<span class="bold">【行知自然平台将利用先进的互联网传输技术，秉持开放的云端分享理念，对中国珍稀野生动植物的科普资源、视频资源、以及专家资源进行系统性整合和专业性研发，从而为国内外中小学校搭建一个可定制、可分级、可持续更新的国际化行知自然教育平台。它不仅能够为校内自然教育补充丰富的教学资源，而且能够拓展教学手段，并为校外公益实践提供高质量的解决方案】，汉雅星空科技有限公司可能会对提供的服务予以变更，具体服务以行知自然教育平台实际提供的为准</span>。
        </div>
        <div>3. 本协议有关行知自然教育平台所享有的全部权利以及承担的全部义务均属于汉雅星空文化科技有限公司所有和承担。</div>
      </div>
    </div>
    <div class="text-box">
      <div class="title">二、用户账号注册与管理</div>
      <div>
        <div class="bold">
          1. 行知自然教育平台账号使用手机号码绑定注册方式，您同意在注册时将使用您提供的手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。
        </div>
        <div class="bold">
          2. 行知自然教育平台账号的所有权归汉雅星空科技有限公司所有，用户完成申请注册手续后，获得账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。
        </div>
        <div>
          3. 在用户注册及使用本服务时，行知自然教育平台需要收集一些用户个人信息，以向用户提供相关服务功能，或在必要时联系用户。行知自然教育平台同意对这些信息的使用将受限于第四条用户个人信息保护条款的约束。
        </div>
        <div>
          4. 用户有责任妥善保管注册账号信息及账号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或密码。在用户怀疑他人使用其账号或密码时，用户可以立即通知行知自然教育平台。
        </div>
        <div class="bold">
          5. 用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，行知自然教育平台有权依据协议不经事先通知中断或终止对违约用户账号提供服务。同时，汉雅星空文化科技有限公司保留在任何时候收回行知自然教育平台账号的权利。
        </div>
      </div>
    </div>
    <div class="text-box">
      <div class="title">三、用户行为规范</div>
      <div class="bold">
        1. 您承诺您在使用本服务过程中所制作、上载、复制、发布、传播的任何内容（包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复的消息和相关链接页面，以及其他使用账号或本服务所产生的内容）及使用本服务过程中的行为应当遵守相关法律法规及本协议。行知自然教育平台有权对您上传、发布或传输的信息进行管理，发现法律法规及本协议禁止发布或者传输的信息的，行知自然教育平台有权不经事先通知立即停止传输该信息，采取删除等处置措施，防止信息扩散，保存有关记录，并向有关部门报告，必要时可能关停用户账户。
      </div>
      <div class="bold">
      2. 用户不得利用行知自然教育平台账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：<br/>
      (1) 反对宪法所确定的基本原则的；<br/>
      (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
      (3) 损害国家荣誉和利益的；<br/>
      (4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
      (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
      (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
      (7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
      (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br/>
      (9) 遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；<br/>
      (10) 含有法律、行政法规禁止的政治、宗教、道德敏感词汇以及内容的信息；<br/>
      (11) 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；<br/>
      (12) 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。
      </div>
      <div class="bold">
        3. 用户不得利用行知自然教育平台账号或本服务制作、上载、复制、发布、传播如下干扰行知自然教育平台的正常运营，以及侵犯其他用户或第三方合法权益的内容：<br/>
        (1) 含有任何性或性暗示的；<br/>
        (2) 含有辱骂、恐吓、威胁内容的；<br/>
        (3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br/>
        (4) 涉及他人隐私、个人或机构信息或资料的；<br/>
        (5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br/>
        (6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
      </div>
      4. 用户在使用本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表行知自然教育平台的观点、立场或政策，行知自然教育平台对此不承担任何责任。<br/>
      <div class="bold">
        5. 用户不得利用本服务从事进行如下行为：<br/>
        (1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义；<br/>
        (2) 强制、诱导其他用户关注、点击链接页面或分享信息；<br/>
        (3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br/>
        (4) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br/>
        (5) 未经行知自然教育平台书面许可利用账号和任何功能进行推广或互相推广的；<br/>
        (6) 利用技术手段批量建立虚假账号；<br/>
        (7) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br/>
        (8) 其它违反法律法规规定、侵犯其他用户合法权益、干扰本软件正常运营的行为。
      </div>
      <div class="bold">
        6. 用户须对利用行知自然教育平台账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与行知自然教育平台无关。如因此给行知自然教育平台或第三方造成损害的，用户应当依法予以赔偿，包括给行知自然教育平台造成的全部直接和间接的损失。
      </div>
      <div class="bold">
        7. 行知自然教育平台提供的服务中可能包括广告，用户同意在使用过程中显示行知自然教育平台和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，行知自然教育平台不承担任何责任。
      </div>

    </div>
    <div class="text-box">
      <div class="title">四、用户个人信息保护</div>
      1. 保护用户个人信息是行知自然教育平台的一贯制度。<br/>
      <div class="bold">2. 您在注册账号或使用本服务的过程中，可能需要填写或提交一些必要的信息，如根据有关法律、法规、规章、规范性文件（以下称“法律法规”）规定需要填写的身份信息。如您提交的信息不完整或不符合法律法规的规定，则可能无法使用本服务或在使用本服务的过程中受到限制。</div>
      <div class="bold">
        3. 您可以根据《隐私政策》所述访问、更正、删除您的个人信息，还可以撤回授权同意、注销账号、进行投诉举报以及相关隐私设置。
      </div>
      4. 行知自然教育平台承诺采取适当的组织和技术安全保护措施，以保护用户个人信息安全，防止个人信息的泄露、损毁、丢失、篡改。<br/>
      <div class="bold">5. 行知自然教育平台根据《隐私政策》存储用户个人信息。如用户停止使用本服务或本服务终止，行知自然教育平台可以从服务器上永久地删除用户的数据。</div>
      <div class="bold">
        6. 行知自然教育平台重视未成年人个人信息的保护。如您是未满18周岁的未成年人，或您因其他原因不具有完全民事行为能力，在注册账号或使用本服务前，应当通知您的监护人阅读本协议和《隐私政策》，并在取得法定监护人的同意前提下方可使用提供的服务。
      </div>
    </div>
    <div class="text-box">
      <div class="title">六、风险承担</div>
      <div class="bold">
        1. 用户理解并同意，行知自然教育平台仅为用户提供自然教育平台网络技术服务，提供信息分享、传送及获取的渠道，用户必须为自己注册账号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对行知自然教育平台及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。行知自然教育平台无法且不会对因用户行为而导致的任何损失或损害承担责任。如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向行知自然教育平台举报或投诉，行知自然教育平台将依本协议约定进行处理。
      </div>
      <div class="bold">
        2. 用户在使用本软件及服务时，须自行承担如下合理风险、行知自然教育平台不可掌控的风险或与行知自然教育平台无关的风险，包括但不限于：
      </div>
      <div class="bold">
        (1) 在行知自然教育平台已经采取适当的安全措施的情况下，由于系统漏洞、黑客入侵或其他不可抗拒因素可能引起的个人信息丢失、泄漏等风险；<br/>
        (2) 由于用户安装的软件与用户的终端设备型号不相匹配所导致的任何问题或损害；<br/>
        (3) 用户在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险；<br/>
        (4) 由于无线网络信号不稳定、无线网络带宽小等原因，所引起的本服务登录失败、资料同步不完整、页面打开速度慢等风险；<br/>
        (5) 因停电、设备故障、黑客入侵、自然灾害、设备正常维护等引起的服务中断的风险；<br/>
        (6) 因不可抗力、法律变化或有权机关实施的行为引起的风险。
      </div>
      <div class="bold">
        3. 用户理解并同意，因业务发展需要，行知自然教育平台保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利。
      </div>

    </div>
    <div class="text-box">
      <div class="title"> 七、知识产权声明</div>
      <div class="bold">
        1. 除本服务中涉及广告的知识产权由相应广告商享有外，行知自然教育平台在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表、界面设计、版面框架、数据和程序、代码、文档、动画等）的知识产权著作权、商标权、专利权、商业秘密等归行知自然教育平台所有；用户在使用本服务中所制作、上载、发布、传播的内容的知识产权归用户或法律规定的相关权利人所有，用户在使用本服务中上载、发布和传播的内容，用户保证其有权在本平台或在使用本服务中上载、发布和传播，并同意行知自然教育平台在全世界范围内、永久性的、不可撤销的、免费的授予我们可对该等内容进行任何形式的使用，未经相关权利人书面同意，用户不得以任何方式实施侵犯他人知识产权的行为，否则应由用户自行承担由此产生的一切法律责任，如该等行为给行知自然教育平台造成不良影响或损害的，用户还应向行知自然教育平台承担包括停止侵权、消除影响以及赔偿一切直接和间接经济损失等的法律责任。<br/>
        2. 除另有特别声明外，行知自然教育平台提供本服务时所依托的软件的著作权、专利权及其他知识产权均归行知自然教育平台所有。<br/>
        3. 行知自然教育平台在本服务中所涉及的图形、文字或其组成，以及其他行知自然教育平台标志及产品、服务名称，其著作权或商标权归行知自然教育平台所有。未经行知自然教育平台事先书面同意，用户不得将行知自然教育平台标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理行知自然教育平台标识的行为。<br/>
        4. 上述及其他任何行知自然教育平台或相关广告商依法拥有的知识产权均受到法律保护，未经行知自然教育平台或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。用户在任何情况下包括本协议终止后均不得实施任何侵犯行知自然教育平台所有的知识产权及其它合法权益的行为，否则用户应向行知自然教育平台承担停止侵权、消除影响以及赔偿一切直接和间接经济损失等的法律责任。
      </div>
      5. 若您认为您的知识产权或其他合法权益被侵犯，请与行知自然教育平台联系，并提供您的姓名（名称）、联系方式和地址、侵权内容的名称和网络地址、构成侵权的初步证明材料，您需要对所提供信息的真实性负责。<br/>
    </div>
    <div class="text-box">
      <div class="title">八、法律责任</div>
      <div class="bold">
        1. 如果行知自然教育平台发现或应国家机关要求或收到他人举报、投诉用户违反法律法规规定及本协议约定的，行知自然教育平台有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录，进行审查、删除、屏蔽，并视用户的行为性质情节严重程度，对违规账号处以包括但不限于警告、设备封禁、功能封禁、账号封禁，直至注销用户账户的处罚，停止向该用户提供服务，并通知用户处理结果。行知自然教育平台也会根据有关政府部门的要求协助调查。<br/>
        2. 用户理解并同意，行知自然教育平台有权依合理判断，根据本协议对违反有关法律法规或本协议规定的行为进行包括但不限于警告、设备封禁、功能封禁、账号封禁，直至注销用户账户的处罚、停止向该用户提供服务，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br/>
        3. 用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿行知自然教育平台与合作公司、关联公司，并使之免受损害。在行知自然教育平台首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，行知自然教育平台有权对用户进行追偿，用户应赔偿行知自然教育平台所遭受的一切直接和间接经济损失。<br/>
      </div>
    </div>
    <div class="text-box">
      <div class="title">九、不可抗力及其他免责事由</div>
      <div class="bold">
        1. 用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断或终止。不可抗力是指不能预见、不能避免且不能克服的客观情况，包括但不限于自然灾害如洪水、地震、瘟疫流行、传染病流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，行知自然教育平台将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，行知自然教育平台及合作单位在法律允许的范围内免责。<br/>
        2. 本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。<br/>
        3. 用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断、终止或不能满足用户要求的风险，因此导致的用户或第三方任何损失，行知自然教育平台不承担任何责任。<br/>
        4. 用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，行知自然教育平台不承担任何责任。<br/>
        5. 用户理解并确认，行知自然教育平台需要定期或不定期地对或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，行知自然教育平台无需为此承担任何责任，但行知自然教育平台应事先进行通告。<br/>
        6. 行知自然教育平台依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成行知自然教育平台的义务或承诺，行知自然教育平台不能保证及时发现违法违规或违约行为或进行相应处理。<br/>
        7. 在任何情况下，行知自然教育平台均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用平台或本服务而遭受的利润损失，承担责任（即使行知自然教育平台已被告知该等损失的可能性亦然）。
      </div>
    </div>
    <div class="text-box">
      <div class="title">十、服务的变更、中断、终止</div>
      <div class="bold">
        1. 鉴于网络服务的特殊性，用户同意行知自然教育平台有权随时变更、中断或终止部分或全部的服务。<br/>
        2. 如发生下列任何一种情形，行知自然教育平台有权变更、中断或终止向用户提供的服务，而无需对用户或任何第三方承担任何责任：
        (1) 根据法律规定用户应提交真实信息，而用户提供的个人或机构资料不真实、或与注册时信息不一致又未能提供合理证明；<br/>
        (2) 用户违反相关法律法规或本协议的约定；<br/>
        (3) 按照法律规定或有权机关的要求；<br/>
        (4) 不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）；<br/>
        （5）出于维护账号或系统安全等紧急情况。
      </div>
    </div>

    <div class="text-box">
      <div class="title">十一、其他</div>
      <div class="bold">
        1. 本协议内容同时包括行知自然教育平台可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容可在行知自然教育平台查询，上述内容一经正式发布，即为本协议不可分割的组成部分，用户继续使用行知自然教育平台提供的服务将视为用户已充分阅读、理解并同意遵守其约束。<br/>
        2. 行知自然教育平台有权在必要时修订本协议，修订后的协议条款一旦公布即代替原来的协议条款，用户可在行知自然教育平台、本服务的最新版本中查阅最新版协议条款。在行知自然教育平台修改协议条款后，如果用户继续使用本服务，即视为接受修改后的协议条款。如果用户不接受修改后的条款，请立即停止使用行知自然教育平台提供的服务，用户继续使用行知自然教育平台提供的服务将被视为接受修改后的协议。<br/>
        3. 行知自然教育平台郑重提醒用户注意本协议中免除行知自然教育平台责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。<br/>
        4. 本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和行知自然教育平台之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户和行知自然教育平台均应将纠纷或争议提交行知自然教育平台住所地有管辖权的人民法院管辖。
      </div>
      5. 若本协议的任何条款被享有合法管辖权的法院裁定为无效或不可执行，则该等条款应在最低必要范围内予以限制或排除，并以最能体现本协议意图的新条款予以替换，以使本协议继续完全有效。<br/>
      6. 本协议构成您与行知自然教育平台之间与您使用本软件相关的整体协议，并在此取代及取消您与行知自然教育平台之间先前存在的与该等使用相关的任何书面或口头协议或理解。<br/>
      7. 本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agreement'
}
</script>

<style scoped lang="less">
p{
  margin-bottom: 12px;
}
.text-box{
  .title{
    font-weight: bold;
    margin: 12px 0;
  }
}
.bold{
  font-weight: bold;
}
</style>
