<template>
  <div  id="AboutNature" index="02" class="d_jump about-nature wow slideInRight" >
    <div class="">
      <div class="title">了解行知自然</div>
      <div class="tips">中国野生动物保护协会x央视网x汉雅星空文化科技有限公司<div style="font-weight: 600;color:#333333">共同打造</div></div>
    </div>
    <div class="video">
      <div class="video-box">
        <img class="video-pause" :src="cdnUrl+'/platform/static/i_play.png'" @click="continuePlay()" v-show="videoStatus === 'pause'">
<!--        <i class="el-icon-video-play video-pause" @click="continuePlay()" v-show="videoStatus === 'pause'"></i>-->
        <video-player  class="video-player vjs-custom-skin"
                       @pause="onPlayerPause($event)"
                       @play="onPlayerPlay($event)"
                       @ended="onPlayEnded($event)"
                       ref="videoPlayer"
                       :playsinline="true"
                       :options="playerOptions"
        ></video-player>
      </div>
      <img class="img-left" :src="cdnUrl + '/platform/static/video_left.png'" >
      <img class="img-right" :src="cdnUrl + '/platform/static/video_right.png'">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutNature',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl,
      videoStatus: 'pause',
      playerOptions: {
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: 'https://cdn.gonature.cn/platform/static/docs/introduce4.mp4' // url地址
        }],
        // poster: 'https://cdn.gonature.cn/platform/static/b_panda.jpg', // 你的封面地址
        width: 950, // 播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
        }
      }
    }
  },
  methods: {
    continuePlay () {
      this.$refs.videoPlayer.player.play()
      this.videoStatus = ''
    },
    onPlayEnded (player) {
      player.load()
      this.videoStatus = 'pause'
    },
    onPlayerPlay (player) {
      player.play()
      this.videoStatus = ''
    },
    onPlayerPause (player) {
      // alert("pause");
      this.videoStatus = 'pause'
    }
  }
}
</script>
<style>
.video-js{
  background: #FFFFFF;
}
.vjs-control-bar{
  display: none !important;
}
.vjs-big-play-button{
  display: none !important;
}</style>
<style scoped>
.home button.el-carousel__button{
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.home .el-input__inner:focus{
  border-color: #68CE1C;
}
</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
