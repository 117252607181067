<template>
  <div class="cooperation d_jump" data-wow-duration ="1.5s" data-wow-delay ="0.2s" index="05" id="Cooperation">
    <div class="title">合作伙伴</div>
    <div class="cooperation-list" >
      <div class="cooperation-img"></div>
      <div class="cooperation-img"></div>
      <div class="cooperation-img"></div>
      <div class="cooperation-img"></div>
    </div>
    <div class="cooperation-list" style="margin-top: 68px;">
      <div class="cooperation-img"></div>
      <div class="cooperation-img"></div>
      <div class="cooperation-img"></div>
      <div class="cooperation-img"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cooperation'
}
</script>

<style scoped>

</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
