<template>
  <div class="home" style="width: 100%">
    <!--试用窗口-->
    <SettleIn v-if="showTry" @closetry="closetry"></SettleIn>
    <div class="header">
      <span class="logo"></span>
      <span class="menu-list">
        <!-- logo_meili -- 魅力自然logo图 -->
        <!-- white_new_3.png -- 行知自然logo图 -->
        <div style="display: inline-block"><img :src="cdnUrl + '/platform/static/white_new_3.png'" style="height: 3.33rem;vertical-align: middle;margin-right: 1rem;"></div>
        <a @click="change(0,'Home')" class="menu-item" style="margin-left: 34px;" :class="{'active': nowSelected=='Home'}">首页</a>
        <a @click="change(1,'AboutNature')" class="menu-item" :class="{'active': nowSelected=='AboutNature'}">了解我们</a>
        <a @click="change(2,'Advantage')" class="menu-item" :class="{'active': nowSelected=='Advantage'}">我们的优势</a>
        <a @click="change(3,'Invitation')" class="menu-item" :class="{'active': nowSelected=='Invitation'}">特邀专家</a>
        <a @click="change(4,'Cooperation')" class="menu-item" :class="{'active': nowSelected=='Cooperation'}" v-show="false">权威合作</a>
        <a class="menu-item" @click="goToLearn">使用手册</a>
<!--        <a @click="change(5,'Footer')" class="menu-item" :class="{'active': nowSelected=='Footer'}">联系我们</a>-->
      </span>
      <span class="login-btn">
        <span class="school" style="margin-right: 1rem;" @click="showSchool()">学校入驻</span>
        <span class="login" @click="showLogin()">登录</span>
      </span>
    </div>
    <div index="01" class="d_jump" id="Home" style="position: relative">
      <el-carousel :interval="5000" arrow="never" loop autoplay :height="bannerHeight + 'px'" style="min-height: 700px;">
        <el-carousel-item style="min-height: 700px;">
          <div class="try-box ">
            <div class="miaoshu animated bounceInDown"></div>
            <div class="try-btn try-btn-application"><span @click="showActivity()" class="application-btn">活动详情</span></div>
          </div>
          <img :src="cdnUrl + '/platform/static/banner_huodong.png'" style="width: 100%;height: auto" ref="bannerHeight" @load="imgload">
        </el-carousel-item>
        <el-carousel-item style="min-height: 700px;">
          <div class="try-box">
            <div class="miaoshu animated bounceInDown">来吧！搭建你的专属自然教育平台！</div>
            <div class="try-btn"><span @click="showSchool()">立即使用</span></div>
          </div>
          <img :src="cdnUrl + '/platform/static/pc.png'" style="width: 100%;height: auto" ref="bannerHeight" @load="imgload">
        </el-carousel-item>
        <el-carousel-item style="min-height: 700px;">
          <div class="try-box">
            <div class="miaoshu animated bounceInDown">探索神奇自然的好伙伴</div>
            <div class="try-btn"><span @click="showSchool()">立即使用</span></div>
          </div>
          <img :src="cdnUrl + '/platform/static/laoshu.png'" style="width: 100%;height: auto" >
        </el-carousel-item>
        <el-carousel-item style="min-height: 700px;">
          <div class="try-box">
            <div class="miaoshu animated bounceInDown">创新自然教育的好帮手</div>
            <div class="try-btn"><span @click="showSchool()">立即使用</span></div>
          </div>
          <img :src="cdnUrl + '/platform/static/girl.png'" style="width: 100%;height: auto"  >
        </el-carousel-item>
      </el-carousel>
    </div>
    <div @click="goToActivity" class="hot-activity" style="position: fixed;top: 50%;
    right: 100px;
    z-index: 999;"><img :src="cdnUrl + '/platform/static/huorebaoming.png'" style="height: 13.33rem;"></div>
    <!--第二部分,了解自然中台-->
    <AboutNature></AboutNature>
    <!--我们的优势-->
    <Advantage></Advantage>
    <!--权威机构-->
    <Authority></Authority>
    <!--精品自然教育资源-->
    <Boutique></Boutique>
    <!--科普专家-->
    <Expert data-wow-duration ="1.5s" data-wow-delay ="0.2s" class="wow bounceInRight"></Expert>
    <!--特邀权威专家-->
    <Invitation></Invitation>
    <!--权威合作-->
    <Cooperation v-show="false" class="wow bounceInRight" data-wow-duration ="1.5s" data-wow-delay ="0.2s"></Cooperation>
    <!--底部-->
    <Footer></Footer>
    <!--登录窗口-->
    <div class="login-box" v-if="isShowLogin">
      <i class="el-icon-close close-position" style="top:0" @click="isShowLogin = false"></i>
      <div class="login-title">学校管理后台登录</div>
      <div class="fenge"></div>
      <div>
        <div class="login-tap">
          <div :class="{'active': loginType == 'password'}" @click="loginType = 'password'">账号密码登录</div>
          <div :class="{'active': loginType == 'yanzhengma'}" @click="loginType = 'yanzhengma'">手机快捷登录</div>
        </div>
        <div v-show="loginType == 'password'">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="username" style="margin-top: 38px">
              <el-input v-model.trim="ruleForm.username" placeholder="请输入账号" ></el-input>
            </el-form-item>
            <el-form-item prop="password" style="margin-bottom: 38px">
              <el-input v-model.trim="ruleForm.password" placeholder="请输入密码" type="password" ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="loginType == 'yanzhengma'">
          <el-form :model="yanZhengMaForm" :rules="rules" ref="yanZhengMaForm">
            <el-form-item prop="phone" style="margin-top: 38px">
              <el-input v-model.trim="yanZhengMaForm.phone" placeholder="请输入手机号" ></el-input>
            </el-form-item>
            <el-form-item prop="yanzhengma" style="margin-bottom: 38px">
              <div style="display: flex;">
                <el-input v-model.trim="yanZhengMaForm.yanzhengma" placeholder="请输入验证码"   style="flex: 1"></el-input>
                <div class="yanzhengma-btn" v-show="count <= 0" @click="sendYanZhengMa()">验证码</div>
                <div class="yanzhengma-btn-load" v-show="count > 0" >{{count}}s</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-checkbox v-model="checked">记住账号</el-checkbox>
          <div class="login-now" @click="goToLogin()">登录</div>
          <div class="login-bottom">
            <div></div>
            <div @click="showSchool()" style="cursor:pointer;">没有账号？立即注册</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* import 'animate.css' */
import { WOW } from 'wowjs'
import Footer from '../components/Footer'
import Cooperation from '../components/Cooperation'
import Invitation from '../components/Invitation'
import Expert from '../components/Expert'
import Boutique from '../components/Boutique'
import Authority from '../components/Authority'
import Advantage from '../components/Advantage'
import AboutNature from '../components/AboutNature'
import SettleIn from '../components/SettleIn'
new WOW({ live: false }).init()
export default {
  name: 'Home',
  components: {
    Footer,
    Cooperation,
    Invitation,
    Expert,
    Boutique,
    Authority,
    Advantage,
    AboutNature,
    SettleIn
  },
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl,
      isShowLogin: false,
      showTry: false,
      bannerHeight: '',
      nowSelected: 'Home',
      checked: null,
      ruleForm: {
        username: '',
        password: ''
      },
      yanZhengMaForm: {
        phone: '',
        yanzhangma: ''
      },
      loginType: 'password', // 登录的类别
      count: 0,
      timer: 0 // 验证码倒计时的时间
    }
  },
  methods: {
    goToActivity () {
      this.$router.push('activity?sign=1')
    },
    goToLearn () {
      window.open(this.$store.state.docsUrl, '_blank')
    },
    closetry () {
      this.showTry = false
    },
    // 查看活动详情
    showActivity () {
      this.$router.push('/activity')
    },
    showSchool () {
      this.change(0, 'Home')
      this.tryStep = 'step1'
      this.isShowLogin = false
      this.showTry = true
    },
    showLogin () {
      /*      this.change(0, 'Home')
      this.showTry = false
      this.isShowLogin = true
      */
      // window.location.href = this.$store.state.manageUrl
      window.open(this.$store.state.manageUrl, '_self')
    },
    // 发送验证码
    sendYanZhengMa () {
      this.count = 60
      this.timer = setInterval(() => {
        this.count = this.count - 1
        if (this.count === 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    // 用户登录的方法
    goToLogin () {
      // 判断当前的登录方式
      // 采用用户名密码的登录方式
      if (this.loginType === 'password') {
        const formName = 'ruleForm'
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!')
          } else {
            this.$message.error('登录失败！')
            return false
          }
        })
      } else if (this.loginType === 'yanzhengma') {
        const formName = 'yanZhengMaForm'
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!')
          } else {
            this.$message.error('登录失败！')
            return false
          }
        })
      }
    },
    imgload () {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight.height
      })
    },
    change1 (ele) {
      this.nowSelected = ele
      document.getElementById(ele).scrollIntoView(true)
    },
    onScroll () {
      const scrolled = document.documentElement.scrollTop || document.body.scrollTop
      if (scrolled >= 625) {
        this.$('.header').addClass('scroll-bottom')
      } else {
        this.$('.header').removeClass('scroll-bottom')
      }
      if (scrolled >= 4935) {
        this.nowSelected = 'Cooperation'
      } else if (scrolled < 4935 && scrolled >= 4000) {
        this.nowSelected = 'Invitation'
      } else if (scrolled < 4000 && scrolled >= 1370) {
        this.nowSelected = 'Advantage'
      } else if (scrolled < 1370 && scrolled >= 625) {
        this.nowSelected = 'AboutNature'
      } else {
        this.nowSelected = 'Home'
      }
    },
    change (index, type) {
      // 用 class="d_jump" 添加锚点
      this.nowSelected = type
      const jump = document.querySelectorAll('.d_jump')
      const total = jump[index].offsetTop
      let distance = document.documentElement.scrollTop || document.body.scrollTop
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50
      if (total > distance) {
        smoothDown()
      } else {
        const newTotal = distance - total
        step = newTotal / 50
        smoothUp()
      }
      function smoothDown () {
        if (distance < total) {
          distance += step
          document.body.scrollTop = distance
          document.documentElement.scrollTop = distance
          setTimeout(smoothDown, 10)
        } else {
          document.body.scrollTop = total
          document.documentElement.scrollTop = total
        }
      }
      function smoothUp () {
        if (distance > total) {
          distance -= step
          document.body.scrollTop = distance
          document.documentElement.scrollTop = distance
          setTimeout(smoothUp, 10)
        } else {
          document.body.scrollTop = total
          document.documentElement.scrollTop = total
        }
      }
    }
  },
  computed: {
  },
  mounted () {
    this.$nextTick(function () {
      // new WOW().init()
      const wow = new WOW({
        live: false
      })
      wow.init()
      window.addEventListener('scroll', this.onScroll)
    })
  }
}
</script>
<style>
.hot-activity{
  cursor: pointer;
  animation: myfirst 5s linear 2s infinite;
}
@keyframes myfirst
{
  0% {
    transform: scale(1);
    /*开始为原始大小*/
  }

  25% {
    transform: scale(1.2);
    /*放大1.1倍*/
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.2);
  }
}
.hot-activity:hover{
  opacity: 0.8;
}
.home button.el-carousel__button{
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.home .el-input__inner:focus{
  border-color: #68CE1C;
}
.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #68CE1C;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #68CE1C;
  border-color: #68CE1C;
}
.el-checkbox__inner:hover{
  border-color: #68CE1C;
}
.el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #68CE1C;
}
</style>
<style scoped lang="less">
@import "../assets/css/home";
</style>
